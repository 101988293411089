import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Home = () => (
  <Box
    display="flex"
    flex={1}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    p={4}
  >
    <Typography variant="subtitle1">
      We buy, sell, repair, refurbish and modify retro video games and consoles.
    </Typography>

    <Box mt={2}>
      <Link href="mailto:hello@retrowave.uk">hello@retrowave.uk</Link>
    </Box>
  </Box>
);

export default Home;
