import Commerce from "@chec/commerce.js";

const checAPIKey = process.env.REACT_APP_CHEC_PUBLIC_KEY;
const devEnvironment = process.env.NODE_ENV === "development";

const commerceConfig = {
  axiosConfig: {
    headers: {
      "X-Chec-Agent": "commerce.js/v2",
      "Chec-Version": "2021-09-29",
    },
  },
};

if (devEnvironment && !checAPIKey) {
  throw Error("Missing REACT_APP_CHEC_PUBLIC_KEY value");
}

export default new Commerce(checAPIKey, devEnvironment, commerceConfig);
