import { forwardRef } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { createTheme } from "@mui/material/styles";

const Link = forwardRef(({ href, ...rest }, ref) => (
  <ReactRouterLink to={href} {...{ ref, ...rest }} />
));

export default createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#54f3f5",
    },
    secondary: {
      main: "#fa483f",
    },
    background: {
      default: "#000",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: Link,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: Link,
      },
    },
  },
});
