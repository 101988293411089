import { useCallback, useEffect, useMemo, useState } from "react";
import identity from "lodash/identity";

import { useCommerceApiCall } from "./util";
import commerce from "../commerce";

export const useProductCategories = () => useCommerceApiCall("categories.list");
export const useProducts = (filter = identity) => {
  const state = useCommerceApiCall("products.list");

  const filteredState = useMemo(
    () => ({
      ...state,
      data: state.data?.filter(filter),
    }),
    [state, filter]
  );

  return filteredState;
};

export const useCart = () => {
  const { data: initialCart } = useCommerceApiCall("cart.retrieve");
  const [cart, setCart] = useState(initialCart);

  useEffect(() => {
    setCart(initialCart);
  }, [initialCart]);

  const addToCart = useCallback(
    (product, quantity = 1) =>
      commerce.cart.add(product.id, quantity).then((item) => {
        setCart(item.cart);
      }),
    []
  );

  return { cart, addToCart };
};
