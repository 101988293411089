import { BrowserRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import CartProvider from "./Components/CartProvider";
import Header from "./Components/Header";
import Routes from "./Routes";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        bgcolor="background.default"
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <CssBaseline />
        <CartProvider>
          <BrowserRouter>
            <Header />
            <Routes />
          </BrowserRouter>
        </CartProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
