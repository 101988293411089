import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Home from "./Pages/Home";
const Store = React.lazy(() => import("./Pages/Store"));

const Router = () => (
  <Suspense fallback={null}>
    <Switch>
      <Route path="/" component={Home} exact={true} />
      {process.env.REACT_APP_ENABLE_STORE === "true" && (
        <Route path="/store" component={Store} />
      )}
      {/* @todo add 404 page */}
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </Suspense>
);

export default Router;
