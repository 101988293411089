import { useContext } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import logo from "../logo.png";
import { CartContext } from "./CartProvider";

const Header = () => {
  const { cart } = useContext(CartContext);

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar disableGutters={true}>
        <Box p={2} flexGrow={1}>
          <Link to="/">
            {/* @todo better sizing */}
            <img style={{ width: 300 }} src={logo} alt="Retrowave UK logo" />
          </Link>
        </Box>

        {process.env.REACT_APP_ENABLE_STORE === "true" && (
          <Box pr={2}>
            <IconButton
              size="large"
              aria-label="shopping cart"
              color="inherit"
              href="/store/basket"
            >
              <Badge
                badgeContent={cart?.total_items}
                invisible={!cart}
                color="primary"
              >
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
