import { useEffect, useMemo, useReducer } from "react";
import invoke from "lodash/invoke";

import commerce from "../commerce";

const ACTION_TYPE_LOAD = "@asyncReducer/load";
const ACTION_TYPE_LOAD_DONE = "@asyncReducer/load/done";

const cache = {};

export const useCommerceApiCall = (fn) => {
  const [state, dispatch] = useReducer(
    (curr, action) => {
      if (action.type === ACTION_TYPE_LOAD) {
        return { ...curr, isLoading: true };
      }

      if (action.type === ACTION_TYPE_LOAD_DONE) {
        return {
          ...curr,
          isLoading: false,
          data: action.payload.data || action.payload,
          error: Boolean(action.error),
        };
      }

      return curr;
    },
    cache[fn] || {
      isLoading: false,
      data: null,
      error: false,
    }
  );

  useMemo(() => {
    cache[fn] = state;
  }, [fn, state]);

  useEffect(() => {
    if (state.isLoading || state.data) return;

    dispatch({ type: ACTION_TYPE_LOAD });

    invoke(commerce, fn)
      .then((result) => {
        console.log(result);
        dispatch({ type: ACTION_TYPE_LOAD_DONE, payload: result });
      })
      .catch((err) => {
        dispatch({ type: ACTION_TYPE_LOAD_DONE, payload: err, error: true });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps -- assume fn never changes to avoid potential for infinite loops!
  }, []);

  return state;
};
