import { createContext } from "react";

import { useCart } from "../lib/hooks";

import Loader from "./Loader";

export const CartContext = createContext();

const CartProvider = (props) => {
  const cart = useCart();

  if (!cart.cart) return <Loader />;

  return <CartContext.Provider value={cart} {...props} />;
};

export default CartProvider;
