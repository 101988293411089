import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

const Loader = ({ message }) => (
  <Box
    display="flex"
    flex={1}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <CircularProgress />
    {message && (
      <Box mt={2}>
        <Typography color="primary" variant="subtitle1">
          {message}
        </Typography>
      </Box>
    )}
  </Box>
);

Loader.propTypes = {
  message: PropTypes.node,
};

Loader.defaultProps = {
  message: "Please wait...",
};

export default Loader;
